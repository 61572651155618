import React from "react";
import Seo from "../../../components/seo";
import Industry from "../../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../../components/layout";
import {Restauration} from '../../../components/svgs/industries';

const RestaurationIndustryPageEn = () => {
  const data = useStaticQuery(graphql`
    query RestaurationIndustryDatasEn {
      allContentfulSecteurs(filter: {contentful_id: {eq: "72qEQKcsgYTajRzrrv7WcA"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[0].node.permalink} 
    lang="en-CA"
    title={data.allContentfulSecteurs.edges[1].node.name} 
    subTitle="Digital signage in"
    icon={<Restauration/>}>
      <Seo title="Restaurant Digital Signage | Digital Signage Restaurant Menu"
           description="Use the dynamic display system developed by Attractif to display your menus and promotions. You can manage by location, or centralize your entire network with our restaurant digital signage solution."
           lang="en-CA"/>
      <Industry lang="en-CA" datas={data.allContentfulSecteurs.edges[1].node}/>
    </Layout>
  )
}

export default RestaurationIndustryPageEn
